import React from 'react';
import {Col} from 'react-bootstrap'

const RetreatHosts = () =>
  <div>
    <Col xs={1} md={2} />
    <Col xs={10} md={8} >
  
      <h3>The best writers use three names....wink wink</h3>
      <Col xs={12} md={6} >
        <h3>Heather Pilder Olson</h3>
        <p>The fabulous...</p>
      </Col>
      <Col xs={12} md={6} >
        <h3>Jenni Prange Boran</h3>
        <p>The amazing...</p>
      </Col>
    </Col>
    <Col xs={1} md={2} />
  </div>

export default RetreatHosts;