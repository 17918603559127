import React from 'react';
import {Col} from 'react-bootstrap'

const Signup = () =>
  <div>
    <Col xs={1} md={2} />
    <Col xs={10} md={8} >
      <h3>Signup at Heather Pilder Olson's website</h3>
      <a href="http://www.heatherpilderolson.com/writers-retreats-at-the-dog-house.html">
        http://www.heatherpilderolson.com/writers-retreats-at-the-dog-house.html
      </a>
    </Col>
    <Col xs={1} md={2} />
  </div>

export default Signup;