import React from 'react';
import {Grid, Col, Carousel} from 'react-bootstrap'

const HomePage = () =>
  <Grid>
    <Col xs={12} md={8} >
      <Carousel>
        <Carousel.Item>
          <img width={960} height={720} alt="We Write On!" src="/img/two_chairs_sunset.jpg" />
          <Carousel.Caption>
            <h3>Welcome to the Writer&apos;s Retreat at the Dog House!</h3>
            <p>(and the cat house)</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img width={960} height={720} alt="We Write On!" src="/img/writers_writing.jpg" /> 
          <Carousel.Caption>
            <h3>Writers Writing</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img width={960} height={720} alt="We Write On!" src="/img/dog_house_from_fairway.jpg" /> 
          <Carousel.Caption>
            <h3>The Dog House, as seen from the grassy field</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img width={960} height={720} alt="We Write On!" src="/img/beach_reflection.jpg" /> 
          <Carousel.Caption>
            <h3>So much reflection!</h3>
            <p>(literally and figuratively)</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Col>
  </Grid>;

export default HomePage;