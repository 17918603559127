import React from 'react';
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom';

import Navigation from './Navigation';
import HomePage from './Home';
import RetreatHosts from './RetreatHosts'
import Signup from './Signup'

import * as routes from './routes'; 

const App = () =>
  <Router>
    <div>
      <Navigation />
      <Route
        exact path={routes.HOME}
        component={HomePage}
      />
      <Route
        exact path={routes.LANDING}
        component={HomePage}
      />
      <Route
        exact path={routes.RETREAT_HOSTS}
        component={RetreatHosts}
      />
      <Route
        exact path={routes.SIGNUP}
        component={Signup}
      />
    </div>
  </Router>

export default App;