import React from 'react';

import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { IndexLinkContainer } from 'react-router-bootstrap';

import * as routes from './routes';

const Navigation = () => 
  <div>
    <Navbar>
      <Nav>
        <IndexLinkContainer to={routes.HOME}>
          <NavItem active={true} eventKey={1}>Home</NavItem>
        </IndexLinkContainer>
      </Nav>
      <Nav>
        <IndexLinkContainer to={routes.RETREAT_HOSTS}>
          <NavItem active={true} eventKey={1}>Your Hosts</NavItem>
        </IndexLinkContainer>
      </Nav>
      <Nav>
        <IndexLinkContainer to={routes.SIGNUP}>
          <NavItem active={true} eventKey={1}>Signup!</NavItem>
        </IndexLinkContainer>
      </Nav>
    </Navbar>
  </div>

export default Navigation;